import { SILogger } from 'public/src/pages/common/monitor/index.js'

export default {
  methods: {
    /**
     * 对商品主图进行质量压缩
     * @param {*} img 图片地址
     * @param {Array} customServiceImgDomains 自研服务的定制商品图片域名列表
     * @param {Array} thirdPartyServiceImgDomains 第三方服务的定制商品图片域名列表
     * @returns
     */
    dprInsert({ img = '', customServiceImgDomains, thirdPartyServiceImgDomains }) {
      let IS_RETINA = false
      if (typeof window !== 'undefined') {
        IS_RETINA = window.devicePixelRatio > 1
        !customServiceImgDomains && (customServiceImgDomains = window.gbCommonInfo.CUSTOMIZATION_PRODUCT_IMAGE_DOMAINS.CUSTOM_SERVICE || [])
        !thirdPartyServiceImgDomains && (thirdPartyServiceImgDomains = window.gbCommonInfo.CUSTOMIZATION_PRODUCT_IMAGE_DOMAINS.THIRD_PARTY_SERVICE || [])
      }
      const width = IS_RETINA ? '400' : '200'
      // 需要外部进行图片裁剪
      if (customServiceImgDomains?.some(item => img.indexOf(item) > -1)) {
        return img
      }
      if (thirdPartyServiceImgDomains?.some(item => img.indexOf(item) > -1)) {
        const str = `x-oss-process=image/resize,w_${width}`
        return img.indexOf('?') > -1 ? `${img}&${str}` : `${img}?${str}`
      }
      SILogger.logWarn({
        tag: 'CUSTOMIZATION_PRODUCT_IMAGE_WARNING', // 定制商品图片域名告警
        message: {
          imgUrl: img,
        }
      }, {
        immediate: true
      })
      return img
    },
  },
}
